<template>
    <div class="container">
      <a-collapse v-model:activeKey="activeKey" style="border-top-left-radius: 15px;border-top-right-radius: 15px;">
        <a-collapse-panel key="1" >
          <template #header>
            <h1>PAYBACKS</h1>
          </template>
          <div class="controller">
            <div class="group-item">
              <div class="item">
                <span>ตัวกรองบริษัท: </span>
                <a-select v-model:value="selectedCompanyID" style="width: 250px">
                  <a-select-option :value="null">ทั้งหมด</a-select-option>
                  <a-select-option v-for="company in company_list" :key="company.id" :value="company.id" >{{ company.name }}</a-select-option>
                </a-select>
              </div>
  
              <div class="item">
                <span>รออนุมัติเท่านั้น: </span>
                <a-switch v-model:checked="show_pending" @change="get_payback(), selectedRowKeys = []" />
              </div>
              <a-button class="item" type="primary" :disabled="!selectedRowKeys.length" @click="bulk_update" style="display: flex;align-items: center;width: fit-content;width: 184.61px">
                <span class="material-symbols-outlined" style="font-size: 18px">check_circle</span>
                <span>อนุมัติทั้งหมด ({{ selectedRowKeys.length }})</span>
              </a-button>
            </div>
  
            <div class="group-item">
              <a-range-picker class="item" v-model:value="datetime" :placeholder="['ตั้งแต่วันที่','จนถึง']" />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse><br>
  
      <div class="wrapper" style="height: 80%;">
        <a-table style="width: 100%" size="small"
          rowKey="id"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="[
            { title: 'วันเวลา', dataIndex: 'created_at' },
            { title: 'บริษัท', key: 'company', ellipsis: true },
            { title: 'จำนวนเงิน', key: 'amount', align: 'center' },
            { title: 'รอบเดือน', key: 'salary_month', align: 'center' },
            { title: 'ชื่อธนาคาร', key: 'bank_name', align: 'center' },
            { title: 'เลขบัญชี', key: 'bank_code', align: 'center' },
            { title: 'สลิป', key: 'transfer_img', align: 'center' },
            { title: 'Status', key: 'status', align: 'center' },
          ]"
          :data-source="filtered_list(order_list)"
          :pagination="false"
          :scroll="{ x: 100 }"
        >
          <template #bodyCell="{ column: { key }, record: { id, company, status_id, amount, status, salary_month, bank_name, bank_code, transfer_img } }">
            <template v-if="key === 'company'">
              <a-tooltip>
                <template #title>{{ company?.name }}</template>
                {{ company?.name }}
              </a-tooltip>
            </template>
            <template v-if="key === 'status'">
              <a-space>
                <a-badge :status="badge_color[status_id]" />
                <a-select v-model:value="status.id" style="width: 150px" @change="update_status(id, $event)">
                  <a-select-option v-for="status in status_list" :key="status.id" :value="status.id" >{{ status.name }}</a-select-option>
                </a-select>
              </a-space>
            </template>
            <template v-if="key === 'amount'">
              {{ amount?.toLocaleString() }}
            </template>
            <template v-if="key === 'transfer_img'">
              <a-image :src="transfer_img" style="width: 100px" />
            </template>
            <template v-if="key === 'salary_month'">
              {{ salary_month }}
            </template>
            <template v-if="key === 'bank_name'">
              {{ bank_name }}
            </template>
            <template v-if="key === 'bank_code'">
              {{ bank_code }}
            </template>
          </template>
        </a-table>
        <a-pagination
          style="margin-top: 1rem;"
          v-model:current="page"
          v-model:pageSize="perpage"
          show-size-changer
          :total="order_total"
          @change="get_payback()"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { notification } from 'ant-design-vue';
  import AdminService from '../api/AdminService';
  import dayjs from 'dayjs';
  
  export default {
    name: 'Order',
    data () {
      return {
        order_list: [],
        company_list: [],
        partner_list: [],
        status_list: [],
        selectedRowKeys: [],
        selectedCompanyID: null,
        show_pending: false,
        activeKey: ['1'],
        badge_color: {
          1: 'processing',
          2: 'success',
          3: 'error'
        },
        page: 1,
        perpage: 10,
        order_total: 0,
        datetime: [dayjs().startOf('day'), dayjs().endOf('day')],
        interval: 5000
      }
    },
    methods: {
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
      },
      async bulk_update() {
        for (var i = 0; i < this.selectedRowKeys.length; i++) {
          const id = this.selectedRowKeys[i]
          this.$message.loading({ content: `กำลังอัพเดทสถานะ ${i+1}/${this.selectedRowKeys.length}`, key: 'bulk_update' });
          await AdminService.update_model('Payback', id, { data: { status_id: 2 } })
        }
        this.$message.success({ content: `อัพเดทสถานะสำเร็จ ${this.selectedRowKeys.length} รายการ`, key: 'bulk_update' });
        this.selectedRowKeys = []
        this.get_payback()
      },
      update_status(id, status_id) {
        AdminService.update_model('Payback', id, { data: { status_id } })
          .then(() => {
            this.$message.success('อัพเดทสถานะสำเร็จ')
            this.get_payback()
          })
      },
      filtered_list(list) {
        return list
          .filter(item => {
            if (this.selectedCompanyID) {
              return item.user?.company_id === this.selectedCompanyID
            }
            return true
          })
      },
      async get_payback(show) {
        if (show) {
          const hide = this.$message.loading();
          setTimeout(hide, 1500);
        }
        const where = []
        if (this.show_pending) {
          where.push(`status_id:1`)
        }
        const res = await AdminService.get_payback({ 
          time_start: this.datetime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          time_end:  this.datetime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          page: this.page, 
          perpage: this.perpage,
          where
        });
        this.order_total = res.total
        this.order_list = res.data
      },
      async order_polling(interval) {
        if (interval) {
          setInterval(() => {
            this.get_payback()
          }, interval)
        }
      },
      async get_select_options() {
        const [company, partner, status] = await Promise.all([
          AdminService.get_model('Company', { id: 'all', page: 1, perpage: 500, load: ['partner']  }),
          AdminService.get_model('Partner', { id: 'all', page: 1, perpage: 500  }),
          AdminService.get_model('Status', { id: 'all', page: 1, perpage: 500  }),
        ])
        this.company_list = company.data
        this.partner_list = partner.data
        this.status_list = status.data
      },
      notify(user) {
        const noti = new Notification('มี Payback เข้ามาใหม่!')
        notification['success']({
          message: 'มี Payback เข้ามาใหม่!',
          description: user,
        });
      }
    },
    watch: {
      order_total(newVal, oldVal) {
        const isNewOrder = dayjs(this.order_list[0].created_at).isAfter(dayjs().startOf('minute'))
        if (newVal > 0 && oldVal !== 0 && isNewOrder) {
          this.notify(this.order_list[0].user?.fullname)
        }
      },
      datetime(newVal) {
        if (newVal.length == 2) {
          this.get_payback(true)
        }
      },
      selectedCompanyID(newVal) {
        if (newVal) {
          this.perpage = 500
        }
      }
    },
    mounted() {
      this.get_payback(true)
      this.get_select_options()
      this.order_polling(this.interval)
    }
  }
  </script>
  